.thank-you-page.form-box form {
    p {
        color: #232323;
    }

    h3 {
        color: #2dccd3;
    }

}

.thank-you-page.form-box {
    display: flex;
    justify-content: center;
    height: 100vh; /* Set the height of the page to 100% of the viewport height */
    border-radius: 32px;
    
  }

  
  .thank-you-page.form-box {
    text-align: center;
    width: 100%;
    height: 100%;
    max-width: 600px;
    background-color: white;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .thank-you-page.form-box form {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: center;
    padding: 30px;
  }
  

/* Assuming this is the CSS file */

button.donate-again.btn-theme {
    background-color: #fcb61c; /* Remove the quotes around the color code */
    transition: background-color 0.3s ease; /* Adding transition for smoother effect */
  }
  
  button.donate-again.btn-theme:hover {
    background-color: #e29e00; /* Change the color when hovered */
    /* Other styles for hover effect */
  }

  button.continue-on.btn-theme {
    background-color: #2dccd3; /* Remove the quotes around the color code */
    transition: background-color 0.3s ease; /* Adding transition for smoother effect */
  }
  
  button.continue-on.btn-theme:hover {
    background-color: #1ba8ab; /* Change the color when hovered */
    /* Other styles for hover effect */
  }