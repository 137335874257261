.btn-group {
    display: flex;
    gap: 10px; /* Adjust the spacing between buttons */
  }
  
  .btn.choice-btn {
    border: 1px solid #5f6368 !important;
    border-radius: 5px;
    background-color: white;
    color: #5f6368;
    padding: 8px 16px;
    font-size: 1rem;
    cursor: pointer;
    transition: background-color 0.3s ease, color 0.3s ease;
  }
  
  .btn.choice-btn:hover, .choice-btn.btn-primary {
    background-color: #2dccd3 !important;
    color: white;
  }
  
  .btn.choice-btn.btn-secondary {
    background-color: white;
    color: #5f6368;
  }
  

  .btn.choice-btn {
    min-height: 62px;
  }